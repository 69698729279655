<template>
  <div class="row frame justify-content-center">
    <div class="row photos justify-content-center">
      <gallery-layout :items="photos"     
        :box-container-class="boxContainerClass"
        :box-init-ratio="boxInitRatio">

        <template slot-scope="scope">
          <a :id="'photo_'+scope.item.id" ></a>
          <a :href="scope.item.link" :title="scope.item.title">
            <!-- <app-image
              :lazy="false"
              :lazy-src="scope.item.sizes.fallback.source"
              :lazy-srcset="scope.item.sizes.thumbnail.source+' '+scope.item.sizes.thumbnail.width+'w, '+scope.item.sizes.thumbnail2.source+' '+scope.item.sizes.thumbnail2.width+'w, '+scope.item.sizes.thumbnail3.source+' '+scope.item.sizes.thumbnail3.width+'w'"
              :size="scope.size"
               ></app-image> -->
            <app-image
              :lazy="false"
              :sizes="[{min_width: null, source: scope.item.sizes.fallback.source}, {min_width: scope.item.sizes.fallback.width, source: scope.item.sizes.thumbnail.source}, {min_width: scope.item.sizes.thumbnail.width, source: scope.item.sizes.thumbnail2.source}, {min_width: scope.item.sizes.thumbnail2.width, source: scope.item.sizes.thumbnail3.source}]"></app-image>
            <!-- <img :src="scope.item.sizes.thumbnail.source"> -->
            <!-- <img
              :src="scope.item.sizes.fallback.source"
              :srcset="scope.item.sizes.thumbnail.source+' '+scope.item.sizes.thumbnail.width+'w, '+scope.item.sizes.thumbnail2.source+' '+scope.item.sizes.thumbnail2.width+'w, '+scope.item.sizes.thumbnail3.source+' '+scope.item.sizes.thumbnail3.width+'w'"
               > -->         
          </a>
        </template>
      </gallery-layout>
    </div>
  </div>
</template>

<script> 
import AppImage from '../shared/app_image.vue'
import GalleryLayout from '../shared/layout.vue'
import lozad from 'lozad';

// Use css-element-queries (https://github.com/marcj/css-element-queries) to be able to specify CSS element queries like .someClass[min-width~="800px"]. Used mainly in MappingBrowser.
const ElementQueries = require("css-element-queries/src/ElementQueries")

export default {
  props: {
    photolist: {
      type: String,
      default: "{}"
    }
  },
  watch: {
  },
  data: function () {
    return {
      photos: [],
      boxContainerClass: 'box-container-margin',
      boxInitRatio: 150,
      observer: null
    }
  },
  computed: {
  },
  components: {
    AppImage,
    GalleryLayout
  },
  methods: {
  },
  created: function () {
  },
  mounted: function () {
    // console.log("lightbox mounted")
    this.photos = JSON.parse(this.photolist)

    const setLoadingState = () => {
      // console.log("setLoadingState")
      ElementQueries.init();
    };

    this.$nextTick(function() {
      // console.log("call ElementQueries.init()")
      ElementQueries.init();      
    });

    this.$el.addEventListener('load', setLoadingState);
    // We remove the event listener as soon as
    // the component is destroyed to prevent
    // potential memory leaks.
    this.$once('hook:destroyed', () => {
      this.$el.removeEventListener('load', setLoadingState);
    });

  },
  updated: function () {
  }
};
  
</script>

<style>
  .box-container-margin {
    margin: 4px;
  }
</style>